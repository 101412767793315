
import { defineComponent, onBeforeMount, Ref, ref } from "vue";
import TrackingList from "@/components/TrackingList.vue";
import RankListWidget from "@/components/RankListWidget.vue";
import NextBlueChipListWidget from "@/components/NextBlueChipListWidget.vue";
import {
  rankListReq,
  WhaleItem,
  nextBlueChipListReq,
  NextBlueChipItem,
} from "@/apis";

export default defineComponent({
  name: "Tracking",
  components: { TrackingList, RankListWidget, NextBlueChipListWidget },
  setup() {
    const rankList: Ref<WhaleItem[]> = ref([]);
    const rankListLoading = ref(false);
    const nextBlueChipList: Ref<NextBlueChipItem[]> = ref([]);
    const nextBlueChipListLoading = ref(false);
    const getRankList = async () => {
      const params = {
        pageSize: 6,
      };
      rankListLoading.value = true;
      const [err, res] = await rankListReq(params);
      rankListLoading.value = false;
      if (err) {
        console.error(err);
      }
      if (res) {
        rankList.value = res.data as WhaleItem[];
      }
    };
    const getNextBlueChipList = async () => {
      const params = {
        pageSize: 6,
        pageNum: 0,
        orderBy: "bc_owner_rate_desc",
      };
      nextBlueChipListLoading.value = true;
      const [err, res] = await nextBlueChipListReq(params);
      nextBlueChipListLoading.value = false;
      if (err) {
        console.error(err);
      }
      if (res) {
        nextBlueChipList.value = res.data as NextBlueChipItem[];
      }
    };
    onBeforeMount(() => {
      getRankList();
      getNextBlueChipList();
    });
    return {
      rankList,
      nextBlueChipList,
      rankListLoading,
      nextBlueChipListLoading,
    };
  },
});
