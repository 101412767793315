
import { defineComponent, watch, ref, PropType, onBeforeMount } from "vue";
import { NextBlueChipItem } from "@/apis";
import LazyImage from "@/components/common/LazyImage.vue";
import OTable, { Record } from "@/components/common/Table.vue";
import { decimalizeNumber } from "@/utils/numberUtils";
import { useRouter } from "vue-router";
import { setImgSize } from "@/utils/setImgSizeUtil";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NextBlueChipListWidget",
  components: { OTable },
  props: {
    dataSource: {
      type: Array as PropType<NextBlueChipItem[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const columns = ref();
    const formatedData = ref<Record[]>([]);
    const router = useRouter();

    const formatData = (data: NextBlueChipItem[]) => {
      const arr: Record[] = (data as NextBlueChipItem[]).map((item) => {
        return {
          collectionName: item.collectionName,
          logo: item.logo,
          itemNum: item.items,
          chgRate: item.bluechipOwnersRate,
          chgRate7D: item.bluechipOwnersChgD7Rate,
          collectionId: item.collectionId,
          contract: item.contract,
        };
      });
      return arr;
    };

    const handleClickRow = (record: Record) => {
      const { contract } = record;
      let routeData = router.resolve({
        name: "CollectionDetail",
        params: { contract },
      });
      window.open(routeData.href, "_self");
    };

    watch(
      () => props.dataSource,
      (newValue) => {
        formatedData.value = formatData(newValue);
      },
      {
        immediate: true,
      }
    );

    onBeforeMount(() => {
      columns.value = [
        {
          key: "nfts",
          title: `${t("general.collection")} / ${t("general.items")}`,
          width: 240,
          render: (h: any, record: Record) => {
            const { logo, collectionName, itemNum, index } = record;
            return h(
              "div",
              {
                class: "flex-1 w-0 flex items-center",
              },
              [
                h(
                  "p",
                  {
                    class: `text-sm text-primary-100 mr-3${
                      (index as number) > 3 ? " opacity-50" : ""
                    }`,
                  },
                  index
                ),
                h(LazyImage, {
                  src: setImgSize(logo as string, "72x72"),
                  class:
                    "w-8 h-8 rounded-full mr-2 border-2 border-solid border-n2",
                }),
                h(
                  "div",
                  {
                    class: "overflow-hidden flex-1",
                  },
                  [
                    h(
                      "p",
                      {
                        class:
                          "text-sm font-bold whitespace-nowrap overflow-hidden overflow-ellipsis",
                        title: collectionName,
                      },
                      collectionName || "---"
                    ),
                    h(
                      "p",
                      {
                        class: "text-xs text-customSet-4",
                      },
                      itemNum + " " + t("general.nfts")
                    ),
                  ]
                ),
              ]
            );
          },
        },
        {
          key: "chgRate",
          title: t("views.NextBlueChip.nDayBlueChipIndex", { n: 7 }),
          align: "right",
          width: 120,
          render: (h: any, record: Record) => {
            const { chgRate, chgRate7D } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              [
                h(
                  "p",
                  {
                    class: "text-right font-bold",
                  },
                  chgRate
                    ? decimalizeNumber(Number(chgRate) * 100) + "%"
                    : "---"
                ),
                h(
                  "p",
                  {
                    class: `text-right ${
                      chgRate7D &&
                      (chgRate7D > 0
                        ? " text-green"
                        : chgRate7D < 0
                        ? " text-red"
                        : "")
                    }`,
                  },
                  chgRate7D
                    ? decimalizeNumber(Number(chgRate7D) * 100, true) + "%"
                    : "---"
                ),
              ]
            );
          },
        },
      ];
    });

    return {
      columns,
      formatedData,
      handleClickRow,
    };
  },
});
