
import { defineComponent, watch, ref, PropType, onBeforeMount } from "vue";
import { WhaleItem } from "@/apis";
import LazyImage from "@/components/common/LazyImage.vue";
import OTable, { Record } from "@/components/common/Table.vue";
import { decimalizeNumber } from "@/utils/numberUtils";
import { useRouter } from "vue-router";
import { setImgSize } from "@/utils/setImgSizeUtil";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RankListWidget",
  components: { OTable },
  props: {
    dataSource: {
      type: Array as PropType<WhaleItem[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const columns = ref();
    const formatedData = ref<Record[]>([]);
    const router = useRouter();

    const formatData = (data: WhaleItem[]) => {
      const arr: Record[] = (data as WhaleItem[]).map((item) => {
        return {
          avatarUrl: item.socialInfo.twitterAvatar || item.maxValImg,
          name:
            item.socialInfo.twitterName || item.ensName || item.walletAddress,
          nftNum: item.holdingNfts,
          holdingValueEth: item.holdingValueEth,
          d30ReturnRate: item.d30PaidSpent ? item.d30ReturnRate : undefined,
          walletAddress: item.walletAddress,
        };
      });
      return arr;
    };

    const handleClickRow = (record: Record) => {
      const { walletAddress } = record;
      let routeData = router.resolve({
        name: "WalletDetail",
        params: {
          addr: walletAddress,
        },
      });
      window.open(routeData.href, "_self");
    };

    watch(
      () => props.dataSource,
      (newValue) => {
        formatedData.value = formatData(newValue);
      },
      {
        immediate: true,
      }
    );

    onBeforeMount(() => {
      columns.value = [
        {
          key: "nfts",
          title: `${t("general.user")} / ${t("general.nfts")}`,
          width: 240,
          render: (h: any, record: Record) => {
            const { avatarUrl, name, nftNum, index } = record;
            return h(
              "div",
              {
                class: "flex-1 w-0 flex items-center",
              },
              [
                h(
                  "p",
                  {
                    class: `text-sm text-primary-100 mr-3${
                      (index as number) > 3 ? " opacity-50" : ""
                    }`,
                  },
                  index
                ),
                h(LazyImage, {
                  src: setImgSize(avatarUrl as string, "72x72"),
                  class:
                    "w-8 h-8 rounded-full mr-2 border-2 border-solid border-n2",
                }),
                h(
                  "div",
                  {
                    class: "overflow-hidden flex-1",
                  },
                  [
                    h(
                      "p",
                      {
                        class:
                          "text-sm font-bold whitespace-nowrap overflow-hidden overflow-ellipsis",
                        title: name,
                      },
                      name || "---"
                    ),
                    h(
                      "p",
                      {
                        class: "text-xs text-customSet-4",
                      },
                      nftNum + " " + t("general.nfts")
                    ),
                  ]
                ),
              ]
            );
          },
        },
        {
          key: "holdingValueEth",
          title: t("general.value(unit)", { unit: t("general.eth") }),
          align: "right",
          render: (h: any, record: Record) => {
            const { d30ReturnRate, holdingValueEth } = record;
            return h(
              "div",
              {
                class: "flex-1 text-sm",
              },
              [
                h(
                  "p",
                  {
                    class: "text-right font-bold",
                  },
                  [
                    h("i", {
                      class: "icon-eth mr-1",
                      style: "font-size: 0.6872rem; font-weight: normal",
                    }),
                    decimalizeNumber(Number(holdingValueEth)),
                  ]
                ),
                h(
                  "p",
                  {
                    class: `text-right ${
                      d30ReturnRate &&
                      (d30ReturnRate > 0
                        ? " text-green"
                        : d30ReturnRate < 0
                        ? " text-red"
                        : "")
                    }`,
                  },
                  d30ReturnRate
                    ? decimalizeNumber(Number(d30ReturnRate) * 100, true) + "%"
                    : "---"
                ),
              ]
            );
          },
        },
      ];
    });

    return {
      columns,
      formatedData,
      handleClickRow,
      t,
    };
  },
});
