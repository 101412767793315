
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import LazyImage from "@/components/common/LazyImage.vue";
import { dateFromNow } from "@/utils/timeUtils";
import { abbreviatedNumber, decimalizeNumber } from "@/utils/numberUtils";
import { TopItem } from "@/apis";
import ItemGroup from "@/components/ItemGroup.vue";
import { useRouter } from "vue-router";
import { setImgSize } from "@/utils/setImgSizeUtil";
import { QueryDataItem } from "@/views/ItemDetail.vue";
import { useI18n } from "vue-i18n";
import twitter from "@/assets/twitter.svg";
import LZString from "lz-string";

interface Colors {
  [key: string]: {
    text: string;
    bg: string;
  };
}

export default defineComponent({
  name: "TrackingItem",
  components: { LazyImage, ItemGroup },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { t } = useI18n();
    const colors: Colors = {
      sold: {
        text: "#ff5f68",
        bg: "rgba(255, 95, 104, 0.2)",
      },
      bought: {
        text: "#3df28e",
        bg: "rgba(61, 242, 142, 0.2)",
      },
      mint: {
        text: "#ffc01f",
        bg: "rgba(255, 192, 31, 0.2)",
      },
    };
    const router = useRouter();
    const avatarUrlRef = ref("");
    const nameRef = ref("");
    const followCountRef = ref("");
    const ethValueRef = ref(0);
    const blueChipRatePrecentRef = ref(0);
    const flipRatePrecentRef = ref(0);
    const transferTypeRef = ref("");
    const collectionNameRef = ref("");
    const transferTimeRef = ref("");
    const transferCountRef = ref(0);
    const priceRef = ref(0);
    const twitterUrlRef = ref("");
    const walletAddressRef = ref("");

    const itemList = ref<TopItem[]>([]);

    const priceStr = computed(() => {
      if (transferTypeRef.value === "mint" && priceRef.value === 0) {
        return t("components.trackingItem.freeMint");
      }
      return (
        (transferTypeRef.value === "sold" ? "Receive" : "Cost") +
        " " +
        decimalizeNumber(priceRef.value, false, "ETH") +
        " " +
        t("general.eth")
      );
    });

    const toItemDetailPage = (itemList: QueryDataItem[]) => {
      let dataStr = "";
      for (let i = 0; i < itemList.length; i++) {
        if (dataStr.length > 10000) break;
        const item = itemList[i];
        if (i === 0) dataStr = item.contract + "@" + item.tokenId;
        else dataStr += "-" + item.tokenId;
      }
      const encodeData = LZString.compressToBase64(dataStr);
      let routeData = router.resolve({
        name: "itemDetail",
        query: { data: encodeData },
      });
      window.open(routeData.href, "_self");
    };

    const toUserPage = () => {
      let routeData = router.resolve({
        name: "WalletDetail",
        params: {
          addr: walletAddressRef.value,
        },
      });
      window.open(routeData.href, "_self");
    };

    onBeforeMount(() => {
      const {
        socialInfo: {
          twitterAvatar,
          twitterName,
          twitterId,
          twitterFollowCount,
          twitterUrl,
        },
        latestTxn: {
          transferType,
          transferCount,
          collectionName,
          transferTime,
          price,
          groupItems,
        },
        maxValImg,
        ensName,
        walletAddress,
        holdingValueEth,
        blueChipRate,
        flipRate,
      } = props.dataSource;
      avatarUrlRef.value = twitterAvatar || maxValImg;
      nameRef.value = twitterName || ensName || walletAddress;
      walletAddressRef.value = walletAddress;
      followCountRef.value = twitterId
        ? abbreviatedNumber(twitterFollowCount)
        : "-1";
      ethValueRef.value = holdingValueEth;
      blueChipRatePrecentRef.value = Number((blueChipRate * 100).toFixed(1));
      flipRatePrecentRef.value = Number((flipRate * 100).toFixed(1));
      transferTypeRef.value = transferType;
      collectionNameRef.value = collectionName;
      transferTimeRef.value = dateFromNow(transferTime * 1000);
      itemList.value = groupItems;
      transferCountRef.value = transferCount;
      priceRef.value = price;
      twitterUrlRef.value = twitterUrl;
    });

    return {
      avatarUrlRef,
      nameRef,
      followCountRef,
      ethValueRef,
      blueChipRatePrecentRef,
      flipRatePrecentRef,
      transferTypeRef,
      collectionNameRef,
      transferTimeRef,
      itemList,
      colors,
      transferCountRef,
      priceRef,
      priceStr,
      twitterUrlRef,
      toItemDetailPage,
      toUserPage,
      setImgSize,
      t,
      twitter,
      decimalizeNumber,
    };
  },
});
