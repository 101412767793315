
import { defineComponent, onBeforeUnmount, ref, Ref, onMounted } from "vue";
import TrackingItem from "./TrackingItem.vue";
import { trackingListReq, WhaleItem } from "@/apis";
import Loading from "@/components/common/Loading.vue";

export default defineComponent({
  name: "TrackingList",
  components: { TrackingItem, Loading },
  setup() {
    const hasMoreData = ref(true);
    const requested = ref(false);
    const loadingTextDom = ref();
    const cursorId = ref("");
    const list: Ref<WhaleItem[]> = ref([]);
    const getTrackingList = async () => {
      const params = {
        cursorId: cursorId.value,
      };
      const [err, res] = await trackingListReq(params);
      requested.value = true;
      if (err) {
        console.error(err);
      }
      if (res) {
        if (!res.data.length) {
          hasMoreData.value = false;
          return;
        }
        list.value = list.value.concat(res.data);
        cursorId.value = res.cursorId.toString();
      }
    };
    let ob: any;
    onMounted(() => {
      ob = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio === 0) return;
          hasMoreData.value && getTrackingList();
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadingTextDom.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });
    return {
      list,
      loadingTextDom,
      getTrackingList,
      requested,
      cursorId,
      hasMoreData,
    };
  },
});
