import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0a577ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tracking-list" }
const _hoisted_2 = {
  key: 0,
  class: "item-list"
}
const _hoisted_3 = {
  key: 1,
  class: "flex-1 text-center my-20 text-n4"
}
const _hoisted_4 = {
  key: 2,
  ref: "loadingTextDom",
  class: "text-base text-center leading-10 text-n4"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tracking_item = _resolveComponent("tracking-item")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.list.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createBlock(_component_tracking_item, {
              class: _normalizeClass({
          'mb-8': true,
        }),
              key: item.latestTxn.txHash,
              dataSource: item
            }, null, 8, ["dataSource"]))
          }), 128))
        ]))
      : (_ctx.requested)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("general.noData")), 1))
        : _createCommentVNode("", true),
    (!(_ctx.requested && _ctx.list.length === 0))
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _createVNode(_component_loading, { loading: _ctx.hasMoreData }, null, 8, ["loading"]),
          (!_ctx.hasMoreData)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("general.end")), 1))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true)
  ]))
}