import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dfc22aee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tracking" }
const _hoisted_2 = { class: "ml-5 side-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tracking_list = _resolveComponent("tracking-list")!
  const _component_rank_list_widget = _resolveComponent("rank-list-widget")!
  const _component_next_blue_chip_list_widget = _resolveComponent("next-blue-chip-list-widget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tracking_list),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_rank_list_widget, {
        class: "mb-16",
        title: _ctx.$t('views.Tracking.rank'),
        dataSource: _ctx.rankList,
        onOnClickMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'rank' }))),
        loading: _ctx.rankListLoading
      }, null, 8, ["title", "dataSource", "loading"]),
      _createVNode(_component_next_blue_chip_list_widget, {
        title: _ctx.$t('views.Tracking.nextBlueChips'),
        dataSource: _ctx.nextBlueChipList,
        onOnClickMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'nextBlueChip' }))),
        loading: _ctx.nextBlueChipListLoading
      }, null, 8, ["title", "dataSource", "loading"])
    ])
  ]))
}